import * as React from "react";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Divider,
  Button,
  Box,
  TransitionComponent,
  Slide,
  TextField,
  Checkbox,
  Input,
} from "@mui/material";
import {
  ArrowRight,
  Clear,
  ArrowLeft,
  TextFormatSharp,
} from "@mui/icons-material";
// import { MemoizedFilterChecklist } from './FilterChecklist'
import Fuse from "fuse.js";
import { ClickAwayListener } from "@mui/base";

export default function FilterMobileSecondary(props) {
  const [filterMenuHeight, setFilterMenuHeight] = React.useState(
    Boolean(props.anchorEl)
      ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
      : 600
  );

  React.useEffect(() => {
    setFilterMenuHeight(
      Boolean(props.anchorEl)
        ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
        : 600
    );
  }, [props.anchorEl]);

  const [data, setData] = React.useState(props.listData);
  const [selectedSchool, setSelectedSchool] = React.useState("");
  // const [schoolList, setSchoolList] = React.useState(props.listData);

  const options = {
    includeScore: true,
    findAllMatches: false,
    keys: ["name"],
    threshold: 0.4,
  };

  const fuse = new Fuse(props.listData, options);

  React.useEffect(() => {
    if (props.isSchool && selectedSchool != "") {
      let schoolsData;
      schoolsData = props.listData;
      const filteredList = fuse.search(selectedSchool.toUpperCase());
      const extractedData = filteredList.map((result) => result.item);
      setData(
        extractedData
        //schoolsData.filter((school) =>
        //school.toUpperCase().includes(selectedSchool.toUpperCase())
        //)
      );
    }
  }, [selectedSchool]);

  React.useEffect(() => {
    setData(props.listData);
    setSelectedSchool("");
  }, [props.listData]);

  const handleCloseClear = () => {
    props.clear();
    props.handleClose();
    props.handleCloseMain();
  };

  const handleCloseView = () => {
    props.handleClose();
    props.handleCloseMain();
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <ClickAwayListener onClickAway={handleCloseView}>
      <div>
        <Box
          sx={{
            //visibility: props.anchorEl == null ? "hidden" : "visibile",
            position: "fixed",
            top: "60px",
            left: "0",
            textAlign: "center",
            paddingTop: "12px",
            paddingBottom: "12px",
            width: "100%",
            height: "100%",
            background: "white",
            borderTop: 1,
            borderColor: "rgba(0, 0, 0, 0.20)",
            zIndex: "9998 !important",
            borderRadius: "15px",
            overflow: "auto",
          }}
        >
          <MenuItem divider="true" onClick={props.handleClose}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowLeft></ArrowLeft>
            </Box>
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              {props.title}
            </Box>
          </MenuItem>
          {props.isSchool && (
            <TextField
              id="searchSchool"
              label="Search"
              //autoComplete="off"
              value={selectedSchool ?? ""}
              onChange={(e) => setSelectedSchool(e.target.value)}
              // onChange={(e) => console.log(e)}
              onKeyDown={(e) => onKeyDown(e)}
              // type="search"
              sx={{
                width: "95%",
                marginLeft: "10px",
                marginTop: "10px",
                marginRight: "15px",
              }}
            />
          )}
          {data.map((item, index) => {
            const isSelected = props.filter.includes(item);
            // console.log("running");
            // const isSelected = checkedItems[item];
            return (
              <MenuItem
                divider="true"
                sx={{ paddingLeft: "12px !important" }}
                id="position-filter"
                key={index}
                onClick={() => {
                  props.handleCheckItem(item);
                }}
              >
                <Checkbox
                  checked={isSelected}
                  disableRipple
                  sx={{
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                    paddingLeft: "0px !important",
                  }}
                ></Checkbox>
                {props.isSchool ? (
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_BLOB_URL +
                        "/college-logos/" +
                        item +
                        ".jpg"
                      }
                      style={{
                        height: "22px",
                        marginRight: "10px",
                        marginBottom: "-5px",
                      }}
                    />
                    {item}
                  </div>
                ) : (
                  item
                )}
              </MenuItem>
            );
          })}
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            visibility: props.anchorEl == null ? "hidden" : "visibile",
            position: "fixed",
            bottom: "0",
            left: "0",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            paddingTop: "12px",
            paddingBottom: "12px",
            width: "100%",
            background: "white",
            borderTop: 1,
            borderColor: "rgba(0, 0, 0, 0.20)",
            zIndex: "9999",
          }}
        >
          <Button
            sx={{ background: "white", mr: "12px" }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={handleCloseClear}
          >
            Clear All
          </Button>
          <Button
            sx={{
              background: "white",
              color: "#BF5700",
              ml: "12px",
              borderColor: "#BF5700",
              whiteSpace: "nowrap",
            }}
            onClick={handleCloseView}
            variant="outlined"
            size="small"
          >
            VIEW RESULTS
          </Button>
        </Box>
      </div>
    </ClickAwayListener>
  );
}
