import * as React from "react";
import { Box, Grid } from "@mui/material";

function ShotChartLegend(props) {
  const worstColor = "rgb(254 86 52 / 75%)";
  const avgColor = "rgb(255 228 0 / 75%)";
  const bestColor = "rgb(47 198 138 / 75%)";

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          fontSize: props.isMobile ? "13px" : "0.9em",
          width: props.isMobile ? "90%" : "75%",
          border: "2px solid black",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "4px",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{
              padding: "3px",
              display: "inline-block",
              textAlign: "center",
              textTransform: "uppercase",
              border: "2px solid white",
              background: worstColor,
            }}
          >
            Below Avg
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              padding: "3px",
              display: "inline-block",
              textAlign: "center",
              textTransform: "uppercase",
              border: "2px solid white",
              background: avgColor,
            }}
          >
            Avg
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              padding: "3px",
              display: "inline-block",
              textAlign: "center",
              textTransform: "uppercase",
              border: "2px solid white",
              background: bestColor,
            }}
          >
            Above Avg
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ShotChartLegend;
