import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Link,
  Card,
  Button,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  Box,
  Select,
  FormControl,
  IconButton,
  Typography,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import {
  FilterList,
  Sort,
  RadioButtonChecked,
  ArrowRight,
  Clear,
  ArrowDownward,
  InfoOutlined,
  Title,
  StarOutline,
  Star,
  Cricle,
  Favorite,
  FavoriteBorder,
  Bookmark,
  BookmarkBorder,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import PlayerTableFooter from "./PlayerTableFooter";
import axios from "axios";
import FilterMenuPosition from "./FilterMenuPosition";
import FilterMenuSchool from "./FilterMenuSchool";
import FilterMenuConference from "./FilterMenuConference";
import FilterMenuState from "./FilterMenuState";
import FilterMenuClass from "./FilterMenuClass";
import FilterMenuRole from "./FilterMenuRole";
import ActiveFiltersBar from "./ActiveFiltersBar";
import SchoolsDataMen from "../data/schools.json";
import SchoolsDataWomens from "../data/schools-womens.json";
import StatesAbbrev from "../data/states_abbrev.json";
import FilterMobile from "./FilterMobile";
import SortMobile from "./SortMobile";
import SavedMobile from "./SavedMobile";
import Fuse from "fuse.js";

export default function PlayerTable(props) {
  const navigate = useNavigate();
  const [currentLeague, setCurrentLeague] = React.useState(props.league);

  // Player table state
  const [playersInTable, setPlayersInTable] = React.useState([]);
  const [allPlayersList, setAllPlayersList] = React.useState([]);
  const [inPortalList, setInPortalList] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(25);
  const [loading, setLoading] = React.useState(false);
  const [tableHeight, setTableHeight] = React.useState(
    props.isMobile
      ? 425
      : window.innerHeight - 392 < 500
      ? 500
      : window.innerHeight - 392
  ); // 250 (footer) + 64 (header) + 78 (buttons)

  // Filtering menus state
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = React.useState(null);
  const [positionAnchorEl, setPositionAnchorEl] = React.useState(null);
  const [schoolAnchorEl, setSchoolAnchorEl] = React.useState(null);
  const [confAnchorEl, setConfAnchorEl] = React.useState(null);
  const [stateAnchorEl, setStateAnchorEl] = React.useState(null);
  const [classAnchorEl, setClassAnchorEl] = React.useState(null);
  const [roleAnchorEl, setRoleAnchorEl] = React.useState(null);
  const [mobileFilterAnchorEl, setMobileFilterAnchorEl] = React.useState(null);

  // Sort menu open/close state
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = React.useState(null);
  const [mobileSortAnchorEl, setMobileSortAnchorEl] = React.useState(null);

  // Update filter menu state

  const filterOpen = Boolean(filterMenuAnchorEl);

  const handleFilterClick = async (event) => {
    props.isMobile
      ? setMobileFilterAnchorEl(event.target)
      : setFilterMenuAnchorEl(event.target);
    // prevent background scrolling on mobile
    props.isMobile
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  };

  const handleFilterClose = () => {
    setFilterMenuAnchorEl(null);
    setMobileFilterAnchorEl(null);
    // force role menu close
    handleRoleMenuClose();
    console.log("closing filter");
    // console.log("document overflow: ", document.body.style.overflow);
    console.log(props.headerMenuOpen);
    if (!props.headerMenuOpen) {
      document.body.style.overflow = "auto";
    }
  };
  // mobile filter
  const mobileFilterOpen = Boolean(mobileFilterAnchorEl);

  // Update position filter menu state
  const handlePositionMenuOpen = (event) => {
    setPositionAnchorEl(event.currentTarget);
  };
  const handlePositionMenuMouseEnter = (event) => {
    setPositionAnchorEl(positionAnchorEl);
  };
  const handlePositionMenuClose = () => {
    setPositionAnchorEl(null);
  };

  // Update school filter menu state
  const [selectedSchool, setSelectedSchool] = React.useState("");
  const [schoolList, setSchoolList] = React.useState(
    props.league === "mens" ? SchoolsDataMen : SchoolsDataWomens
  );
  const handleSchoolMenuOpen = (event) => {
    setSchoolAnchorEl(event.currentTarget);
  };
  const handleSchoolMenuMouseEnter = (event) => {
    setSchoolAnchorEl(schoolAnchorEl);
  };
  const handleSchoolMenuClose = () => {
    setSchoolAnchorEl(null);
  };

  React.useEffect(() => {
    let schoolsData;
    if (props.league === "mens") {
      schoolsData = SchoolsDataMen;
    } else {
      schoolsData = SchoolsDataWomens;
    }
    const options = {
      includeScore: true,
      findAllMatches: false,
      keys: ["name"],
      threshold: 0.4,
    };

    const fuse = new Fuse(schoolsData, options);

    const filteredList = fuse.search(selectedSchool.toUpperCase());
    const extractedData = filteredList.map((result) => result.item);

    setSchoolList(
      selectedSchool == "" ? schoolsData : extractedData
      //schoolsData.filter((school) =>
      //  school.toUpperCase().includes(selectedSchool.toUpperCase())
      //)
    );
  }, [selectedSchool]);

  // Update conference filter menu state
  const handleConfMenuOpen = (event) => {
    setConfAnchorEl(event.currentTarget);
  };
  const handleConfMenuMouseEnter = (event) => {
    setConfAnchorEl(confAnchorEl);
  };
  const handleConfMenuClose = () => {
    setConfAnchorEl(null);
  };

  // Update home state filter menu state
  const handleStateMenuOpen = (event) => {
    setStateAnchorEl(event.currentTarget);
  };
  const handleStateMenuMouseEnter = (event) => {
    setStateAnchorEl(stateAnchorEl);
  };
  const handleStateMenuClose = () => {
    setStateAnchorEl(null);
  };

  // Update class filter menu state
  const handleClassMenuOpen = (event) => {
    setClassAnchorEl(event.currentTarget);
  };
  const handleClassMenuMouseEnter = (event) => {
    setClassAnchorEl(classAnchorEl);
  };
  const handleClassMenuClose = () => {
    setClassAnchorEl(null);
  };

  // Update role filter menu state
  const handleRoleMenuOpen = (event) => {
    setRoleAnchorEl(event.currentTarget);
  };
  const handleRoleMenuMouseEnter = (event) => {
    setRoleAnchorEl(roleAnchorEl);
  };
  const handleRoleMenuClose = () => {
    setRoleAnchorEl(null);
  };

  // Update sort menu state
  const sortOpen = Boolean(sortMenuAnchorEl);
  const handleSortClick = (event) => {
    props.isMobile
      ? setMobileSortAnchorEl(event.currentTarget)
      : setSortMenuAnchorEl(event.currentTarget);
    // prevent background scrolling on mobile
    props.isMobile
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  };
  const handleSortClose = () => {
    props.isMobile ? setMobileSortAnchorEl(null) : setSortMenuAnchorEl(null);
    if (!props.headerMenuOpen) {
      document.body.style.overflow = "auto";
    }
  };
  // mobile sort
  const mobileSortOpen = Boolean(mobileSortAnchorEl);

  // Fetch player data
  const fetchPlayerList = async () => {
    setLoading(true);
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playertable?league=` +
        props.league +
        "&season=" +
        props.season
    );

    // get portal players from API, merge into allPlayersList state
    buildPortalPlayersList(data.data.boxscores);

    if (!props.inPortalChecked) {
      setPlayersInTable(allPlayersList);
    }

    setLoading(false);
  };

  // Title bar data
  // year information
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 5; i++) {
    years.push(currentYear - i - 1 + "-" + (currentYear - i));
  }

  // const [selectedSeason, setselectedSeason] = React.useState(years[0]);

  const handleSeasonSelect = (year) => {
    props.updateSelectedSeason(year);
  };

  function seasonFormat(year) {
    const newYear = year.substring(0, 4) + "-" + year.substring(7);
    return newYear;
  }

  React.useEffect(() => {
    const fetchController = new AbortController();
    const fetchSignal = fetchController.signal;

    const fetchPlayerListWithSignal = async () => {
      try {
        setLoading(true);
        const data = await axios.get(
          `${process.env.REACT_APP_FLASK_URL}/playertable?league=${props.league}&season=${props.season}`,
          { signal: fetchSignal }
        );

        // get portal players from API, merge into allPlayersList state
        buildPortalPlayersList(data.data.boxscores);

        if (!props.inPortalChecked) {
          setPlayersInTable(allPlayersList);
        }

        setLoading(false);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          console.error("Error:", error);
        }
      }
    };

    fetchPlayerListWithSignal();

    return () => {
      fetchController.abort();
    };
  }, [props.season, props.league]);

  // Title
  const initialTitle =
    props.league === "mens" ? "Men's Basketball" : "Women's Basketball";
  const [title, setTitle] = React.useState(initialTitle);

  function includesFavorites(params) {
    var includes = false;
    for (var i = 0; i < props.favoritesList.length; i++) {
      if (props.favoritesList[i] === params.id) {
        includes = true;
        break;
      }
    }
    return includes;
  }

  const [favoritesListItems, setFavoritesListItems] = React.useState([]);

  function createFavoriteItems() {
    const favorites = [];
    props.favoritesList.forEach((id) => {
      const item = allPlayersList.find((player) => player.playerid === id);
      if (item) {
        favorites.push(item);
      }
    });
    favorites.sort((a, b) => a.name.localeCompare(b.name));
    setFavoritesListItems(favorites);
  }

  // const handleFavorites = (params) => {
  //   var includes = includesFavorites(params);
  //   if (includes) {
  //     const updatedArray = favorites.filter((item) => item.id !== params.id);
  //     setFavorites(updatedArray);
  //   } else {
  //     const updatedArray = [params, ...favorites];
  //     setFavorites(updatedArray);
  //   }
  // };

  React.useEffect(() => {
    if (props.favoritesList.length === 0) {
      handleFavoritesClose();
    }
    createFavoriteItems();
  }, [props.favoritesList]);

  function transferColor(params) {
    let transferIndicatorColor;
    if (params.portalStatus === "In Portal") {
      transferIndicatorColor = "#2FC68A";
    } else if (params.portalStatus === "Transferred") {
      transferIndicatorColor = "#FE5634";
    } else {
      transferIndicatorColor = "#D8D8D8";
    }
    return transferIndicatorColor;
  }

  const [favoritesMenuAnchorEl, setFavoritesMenuAnchorEl] =
    React.useState(null);

  const [mobileSavedAnchorEl, setMobileSavedAnchorEl] = React.useState(null);

  const favoritesOpen = Boolean(favoritesMenuAnchorEl);
  const favoritesOpenMobile = Boolean(mobileSavedAnchorEl);

  const handleFavoritesClick = (event) => {
    props.isMobile
      ? setMobileSavedAnchorEl(event.currentTarget)
      : setFavoritesMenuAnchorEl(event.currentTarget);
    props.isMobile
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
    createFavoriteItems();
  };

  const handleFavoritesClose = () => {
    setFavoritesMenuAnchorEl(null);
    setMobileSavedAnchorEl(null);
    if (!props.headerMenuOpen) {
      document.body.style.overflow = "auto";
    }
  };

  // TODO: remove, this will be done in database
  // merges portal players into allPlayers state
  const buildPortalPlayersList = (allPlayers) => {
    // defualt all players to not in portal
    // console.log(allPlayers);
    allPlayers.forEach((player) => (player.portalStatus = "Not in Portal"));

    fetch(
      `${process.env.REACT_APP_STORAGE_BLOB_URL}/${props.league.substring(
        0,
        1
      )}-portal-list/portal-players.json`
    )
      .then((response) => {
        return response.json();
      })
      .then((portalJSON) => {
        // put portal players into a set to prevent duplicates
        const portalList = new Set();

        // for every player in the JSON, find if that player is in the allPlayersList state
        for (let i = 0; i < portalJSON.length; i++) {
          for (let j = 0; j < allPlayers.length; j++) {
            if (
              allPlayers[j].name === portalJSON[i].name_synergy &&
              allPlayers[j].teamname === portalJSON[i].school_synergy
            ) {
              // update player's portalStatus attribute if found in JSON
              // if (props.league === "mens") {
              //   allPlayers[j].portalStatus = portalJSON[i].portal_status;
              //   portalList.add(allPlayers[j]);
              //   break;
              // } else if (props.league === "womens") {
              //   allPlayers[j].portalStatus = "In Portal";
              //   portalList.add(allPlayers[j]);
              //   break;
              // }
              allPlayers[j].portalStatus = portalJSON[i].portal_status;
              portalList.add(allPlayers[j]);
              break;
            }
          }
        }

        setInPortalList([...portalList]);
        if (props.inPortalChecked) {
          setPlayersInTable(portalList);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });

    // update allPlayersList state with updated list with portalStatus
    setAllPlayersList(allPlayers);
  };

  // TODO: remove, this will be done in database
  const findPortalStatus = (name, teamname) => {
    for (let i = 0; i < inPortalList.length; i++) {
      if (
        name === inPortalList[i].name &&
        teamname === inPortalList[i].teamname
      ) {
        return inPortalList[i].portalStatus;
      }
    }
    return "Not in Portal";
  };

  // const history = useHistory();
  // Handle clicking player name
  const handleClickTable = (params) => {
    props.updateSelectedPlayer(
      params.row.playerid,
      props.season,
      params.row.history,
      params.row.portalStatus
    );
    // navigate(
    //   "/player/" + props.league + "/" + props.season + "/" + params.row.playerid
    // );
  };

  // Handle clicking player name in saved list
  const handleClick = (params) => {
    props.updateSelectedPlayer(
      params.playerid,
      props.season,
      params.history,
      params.portalStatus
    );
    navigate(
      "/player/" + props.league + "/" + props.season + "/" + params.playerid
    );
    document.body.style.overflow = "auto";
  };

  // Handle clicking school name
  const handleSchoolClick = (event, params) => {
    props.filterOneSchoolOnly(event, params.row.teamname);
  };

  // Handle clicking portal toggle
  const handleInPortalSwitch = (event) => {
    props.updateInPortalToggle(event);
    event.target.checked === true
      ? setPlayersInTable(inPortalList)
      : setPlayersInTable(allPlayersList);
  };

  // Filter players in the active list
  const filterPlayersInTable = () => {
    let activeList = allPlayersList;
    if (props.inPortalChecked) {
      activeList = inPortalList;
    }
    // No filters, use active list
    if (
      props.positionFilters.length === 0 &&
      props.schoolFilters.length === 0 &&
      props.confFilters.length === 0 &&
      props.stateFilters.length === 0 &&
      props.classFilters.length === 0 &&
      props.roleFilters.length === 0 &&
      !props.savedPlayerFilter
    ) {
      setPlayersInTable(activeList);
    } else {
      const newList = [];
      for (let i = 0; i < activeList.length; i++) {
        let currentPlayer = activeList[i];
        let hometown = currentPlayer.hometown;
        let abbrev = hometown.substring(hometown.lastIndexOf(",") + 1).trim();
        let stateName = StatesAbbrev[abbrev];
        let playerClass = currentPlayer.playerclass;
        let position = currentPlayer.pos;
        let positionCorrect = false;
        let savedCorrect = props.favoritesList.includes(currentPlayer.playerid);
        let roleFilter = true; // true if player passes role filters
        // if player has two positions, check if both positions are being filtered
        if (playerClass == "NaN") {
          playerClass = "Other";
        }
        if (position.includes("-")) {
          let firstPos = position.substring(0, 1);
          let secondPos = position.substring(2, 3);
          if (
            props.positionFilters.includes(firstPos) ||
            props.positionFilters.includes(secondPos)
          ) {
            positionCorrect = true;
          }
        } else if (props.positionFilters.includes(position)) {
          positionCorrect = true;
        }
        for (let i = 0; i < props.roleFilters.length; i++) {
          let f = props.roleFilters[i];
          if (f.role && f.threshold) {
            if (f.operator === "less_than") {
              if (parseFloat(currentPlayer[f.role]) < f.threshold) {
                roleFilter = true;
              } else {
                roleFilter = false;
                break;
              }
            } else if (f.operator === "greater_than") {
              if (parseFloat(currentPlayer[f.role]) > f.threshold) {
                roleFilter = true;
              } else {
                roleFilter = false;
                break;
              }
            }
          } else {
            roleFilter = true;
          }
        }
        if (
          roleFilter &&
          (positionCorrect || props.positionFilters.length === 0) &&
          (props.schoolFilters.includes(currentPlayer.teamname) ||
            props.schoolFilters.length === 0) &&
          (props.confFilters.includes(currentPlayer.conference) ||
            props.confFilters.length === 0) &&
          (props.stateFilters.includes(stateName) ||
            props.stateFilters.length === 0) &&
          (props.classFilters.includes(playerClass) ||
            props.classFilters.length === 0) &&
          (!props.savedPlayerFilter ||
            (savedCorrect && props.savedPlayerFilter))
        ) {
          newList.push(currentPlayer);
        }
      }
      setPlayersInTable(newList);
    }
  };

  // IOS style toggle
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#2FC68A",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#d8d8d8" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const getRoleDisplayName = (sortModelRoleName) => {
    if (sortModelRoleName === "rimprotector") {
      return "rim protector";
    } else {
      return sortModelRoleName;
    }
  };

  function sortingColor(field, asc, border) {
    const roleDisplayName = getRoleDisplayName(field);
    if (
      roleDisplayName === "name" ||
      roleDisplayName === "position" ||
      roleDisplayName === "class" ||
      roleDisplayName === "height" ||
      roleDisplayName === "weight" ||
      roleDisplayName === "teamname"
    ) {
      if (border) {
        return "#ADADAD";
      } else {
        return "#5B5B5B";
      }
    } else if (asc) {
      return "rgb(254, 86, 52)";
    }
    return "rgb(47, 198, 138)";
  }

  function sortingText(field, asc) {
    const roleDisplayName = getRoleDisplayName(field);
    if (roleDisplayName === "name" && asc) {
      return "Names (A-Z)";
    } else if (roleDisplayName === "name" && !asc) {
      return "Names (Z-A)";
    } else if (roleDisplayName === "position" && asc) {
      return "Positions (A-Z)";
    } else if (roleDisplayName === "position" && !asc) {
      return "Positions (Z-A)";
    } else if (roleDisplayName === "class" && asc) {
      return "Class (A-Z)";
    } else if (roleDisplayName === "class" && !asc) {
      return "Class (Z-A)";
    } else if (roleDisplayName === "height" && asc) {
      return "Shortest";
    } else if (roleDisplayName === "height" && !asc) {
      return "Tallest";
    } else if (roleDisplayName === "weight" && asc) {
      return "Asc Weights";
    } else if (roleDisplayName === "weight" && !asc) {
      return "Desc Weights";
    } else if (roleDisplayName === "teamname" && asc) {
      return "School (A-Z)";
    } else if (roleDisplayName === "teamname" && !asc) {
      return "School (Z-A)";
    } else if (asc) {
      return "Worst " + roleDisplayName + "s";
    }
    return "Best " + roleDisplayName + "s";
  }

  const getScoreTextColor = (score) => {
    return "black";
  };

  const getScoreCellColor = (score) => {
    if (score >= 85) {
      const alpha = (score - 85) / 15;
      return "rgba(47, 198, 138, " + alpha + ")";
    } else if (score <= 15) {
      const alpha = 1 - score / 15;
      return "rgba(254, 86, 52, " + alpha + ")";
    }
  };

  // let nameWidth = props.league === "mens" ? 200 : 190;
  // let nameLinkWidth = props.league === "mens" ? (props.season === years[0] ? "115px" : "130px") : (props.season === years[0] ? "128px" : "143px");
  // let hideWeight = props.league === "mens" ? false : true;
  // let schoolWidth = props.league === "mens" ? 147 : 160;
  // let schoolTextWidth = props.league === "mens" ? "110px" : "119px";

  let nameWidth = 190;
  let nameLinkWidth = props.season === years[0] ? "120px" : "135px";
  let schoolWidth = props.isMobile ? 65 : 160;
  let schoolTextWidth = "119px";

  const heightComparator = (v1, v2) =>
    getHeightInches(v1) - getHeightInches(v2);

  function getHeightInches(height) {
    const [feetStr, inchesStr] = height.split("-");
    const feet = parseInt(feetStr, 10) || 0;
    const inches = parseInt(inchesStr, 10) || 0;
    return feet * 12 + inches;
  }

  function getPositionCompare(pos1, pos2) {
    if (pos1 < pos2) {
      return -1;
    } else if (pos1 > pos2) {
      return 1;
    }
    return 0;
  }

  function getNILNumber(nil) {
    if (nil < 100) {
      return 2;
    } else if (nil < 200) {
      return 1;
    } else if (nil < 200) {
      return 2;
    } else {
      return 5;
    }
  }

  // Columns in data table
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: nameWidth,
      renderHeader: () => <div style={{ marginLeft: "35px" }}>Name</div>,
      renderCell: (params) => {
        let transferIndicatorColor;
        if (props.season != years[0]) {
          transferIndicatorColor = "#D8D8D8";
        } else if (params.row.portalStatus === "In Portal") {
          transferIndicatorColor = "#2FC68A";
        } else if (params.row.portalStatus === "Transferred") {
          transferIndicatorColor = "#FE5634";
        } else {
          transferIndicatorColor = "#D8D8D8";
        }
        const nil = parseFloat(params.row.nil).toFixed(1);
        const nilNumber = getNILNumber(nil);
        return (
          <div style={{ position: "absolute" }}>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              {includesFavorites(params) ? (
                <IconButton disableRipple sx={{ padding: 0, paddingRight: 1 }}>
                  <Star
                    sx={{ color: "#BF5700" }}
                    onClick={() => props.removeFavorite(params.id)}
                  ></Star>
                </IconButton>
              ) : (
                <div className="favorites-button">
                  <IconButton
                    disableRipple
                    sx={{ padding: 0, paddingRight: 1 }}
                  >
                    <StarOutline
                      sx={{
                        color: "#BF5700",
                      }}
                      onClick={() => props.addFavorite(params.id)}
                    ></StarOutline>
                  </IconButton>
                </div>
              )}
              {props.season == years[0] ? (
                <RadioButtonChecked
                  fontSize="small"
                  sx={{ color: transferIndicatorColor, marginRight: "5px" }}
                />
              ) : (
                <></>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Link
                  underline="hover"
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                    //width: props.season === years[0] ? nameLinkWidth : nameLinkWidth + 2,
                    width: nameLinkWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={(event) => {
                    // if (!event.ctrlKey && !event.metaKey) {
                    //   handleClickTable(params);
                    // }
                    handleClickTable(params);
                  }}
                  href={`/player/${props.league}/${props.season}/${params.row.playerid}`}
                >
                  {params.row.name} &nbsp;{" "}
                  <span style={{ fontSize: "1.3em", lineHeight: "1em" }}>
                    &#8250;
                  </span>
                </Link>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "position",
      headerName: "Pos",
      minWidth: 45,
      width: 45,
      valueGetter: (params) => {
        return params.row.pos != "NaN" && params.row.pos ? params.row.pos : "";
      },
    },
    {
      field: "class",
      headerName: "Class",
      width: 54,
      valueGetter: (params) => {
        return params.row.playerclass != "Nan" ? params.row.playerclass : "";
      },
    },
    {
      field: "height",
      headerName: "Ht",
      minWidth: 40,
      width: 42,
      renderCell: (params) => {
        return params.row.height != "NaN" && params.row.height
          ? params.row.height
          : "";
      },
      sortComparator: heightComparator,
    },
    // {
    //   field: "weight",
    //   hide: hideWeight,
    //   headerName: "Wt",
    //   minWidth: 40,
    //   width: 42,
    //   renderCell: (params) => {
    //     return params.row.weight ? params.row.weight : "";
    //   },
    // },
    {
      field: "teamname",
      headerName: "School",
      // width: props.isMobile ? "100px" : schoolWidth,
      width: schoolWidth,
      // width: "fit",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              //width: props.isMobile ? "50px" : "152px",
              // width: "fit",
            }}
          >
            <img
              src={
                process.env.REACT_APP_STORAGE_BLOB_URL +
                "/college-logos/" +
                params.row.teamname +
                ".jpg"
              }
              style={{ height: "22px", marginRight: "8px", marginTop: "-8px" }}
            />
            {!props.isMobile && (
              <div>
                <Link
                  underline="hover"
                  sx={{
                    cursor: "pointer",
                    width: schoolTextWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginTop: "4px",
                    display: "block",
                  }}
                  onClick={(event) => handleSchoolClick(event, params)}
                >
                  {params.row.teamname}
                </Link>
                <div
                  style={{
                    fontSize: "0.7em",
                    width: schoolTextWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.row.conference}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "scorer",
      type: "number",
      headerName: "Scorer",
      width: 84,
      renderCell: (params) => {
        const score = parseFloat(params.row.scorer).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
    {
      field: "shooter",
      type: "number",
      headerName: "Shooter",
      width: 84,
      renderCell: (params) => {
        const score = parseFloat(params.row.shooter).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
    {
      field: "finisher",
      type: "number",
      headerName: "Finisher",
      width: 84,
      renderCell: (params) => {
        const score = parseFloat(params.row.finisher).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
    {
      field: "playmaker",
      type: "number",
      headerName: "Playmaker",
      width: 85,
      renderCell: (params) => {
        const score = parseFloat(params.row.playmaker).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
    {
      field: "rebounder",
      type: "number",
      headerName: "Rebounder",
      width: 85,
      renderCell: (params) => {
        const score = parseFloat(params.row.rebounder).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
    {
      field: "defender",
      type: "number",
      headerName: "Defender",
      width: 84,
      renderCell: (params) => {
        const score = parseFloat(params.row.defender).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
    {
      field: "rimprotector",
      headerName: "Rim Protector",
      type: "number",
      renderHeader: (params) => {
        return (
          <Box sx={{ textAlign: "right", lineHeight: "1.1em" }}>
            Rim<br></br>Protector
          </Box>
        );
      },
      width: 85,
      renderCell: (params) => {
        const score = parseFloat(params.row.rimprotector).toFixed(1);
        return (
          <div
            className="scoreHighlight"
            style={{
              color: getScoreTextColor(score),
              backgroundColor: getScoreCellColor(score),
            }}
          >
            {score}
          </div>
        );
      },
    },
  ];

  // Fetch data after component loads
  React.useEffect(() => {
    if (props.league !== currentLeague) {
      props.clearFilters();
      setCurrentLeague(props.league);
    }
    if (props.league === "mens") {
      setSchoolList(SchoolsDataMen);
    } else {
      setSchoolList(SchoolsDataWomens);
    }
    setPlayersInTable([]);
    setTitle(
      props.league === "mens" ? "Men's Basketball" : "Women's Basketball"
    );
    // Reset player page first render state
    props.updateFirstRender(true);
    //fetchPlayerList();
  }, [props.league]);

  // Filter data when filters change
  React.useEffect(() => {
    filterPlayersInTable();
  }, [
    props.positionFilters,
    props.schoolFilters,
    props.confFilters,
    props.stateFilters,
    props.classFilters,
    props.roleFilters,
    props.inPortalChecked,
    inPortalList,
    props.sortModel,
    props.savedPlayerFilter,
  ]);

  // Adjust table height on window resize
  React.useEffect(() => {
    const handleResize = () => {
      setTableHeight(
        window.innerHeight - 392 < 500 ? 500 : window.innerHeight - 392
      );
    };
    const debouncedResize = debounce(handleResize, 200);

    window.addEventListener("resize", debouncedResize);

    // cleanup listener
    return (_) => {
      window.removeEventListener("resize", debouncedResize);
    };
  });

  // to debounce window resize
  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        position: "relative",
        paddingLeft: props.isMobile ? "18px !important" : "48px !important",
        paddingRight: props.isMobile ? "18px !important" : "48px !important",
      }}
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Container
        sx={{
          alignItems: "center",
          display: "inline-flex",
        }}
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "0px",
        }}
        disableGutters
      >
        <Box
          sx={{
            background: "white",
            marginRight: "8px !important",
            mt: 3,
            display: "flex",
            border: 1,
            borderRadius: 1,
            height: 40,
            paddingLeft: 1,
            alignItems: "center",
            paddingRight: 1,
            textAlign: "center",
            borderColor: "#ADADAD",
            width: props.isMobile ? "100%" : "fit",
            marginTop: props.isMobile ? "15px !important" : "24px !important",
          }}
        >
          <Typography
            component="div"
            sx={{
              fontWeight: "bold",
              marginRight: "3px",

              typography: {
                xs: { fontSize: "16px" },
                sm: { fontSize: props.league == "mens" ? "20px" : "18px" },
                m: { fontSize: "20px" },
                lg: { fontSize: "24px" },
                xl: { fontSize: "28px" },
              },
            }}
          >
            {title}
          </Typography>
          <div
            style={{
              display: "inline-flex",
              float: "right",
              marginLeft: "auto",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1 }}>
              <Select
                value={seasonFormat(props.season)}
                sx={{
                  padding: "0px !important",
                  margin: "0px !important",
                  "& .MuiInputBase-input": {
                    padding: "0px",
                    margin: "0px",
                  },
                }}
              >
                {years.map((year) => (
                  <MenuItem
                    value={seasonFormat(year)}
                    key={year}
                    onClick={() => handleSeasonSelect(year)}
                  >
                    {seasonFormat(year)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.isMobile ? (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    arrow
                    title="Rosters and stats reflect the selected season. Role scores are projections for the following season."
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={tooltipOpen}
                    onClose={handleTooltipClose}
                  >
                    <IconButton
                      disableRipple
                      id="info button"
                      onClick={handleTooltipOpen}
                      sx={{
                        padding: "0px !important",
                        margin: "0px !important",
                      }}
                    >
                      <InfoOutlined
                        sx={{
                          color: "#E1E1E1",
                          margin: "0px",
                          padding: "0px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <Tooltip
                arrow
                title="Rosters and stats reflect the selected season. Role scores are projections for the following season."
              >
                <IconButton
                  disableRipple
                  id="info button"
                  sx={{
                    padding: "0px !important",
                    margin: "0px !important",
                  }}
                >
                  <InfoOutlined
                    sx={{
                      color: "#E1E1E1",
                      margin: "0px",
                      padding: "0px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <Button
          id="filter-button"
          sx={{
            background: "white",
            mt: 3,
            // ml: 1,
            marginRight: "8px !important",
            paddingLeft: 1.5,
            paddingRight: 1.5,
            marginTop: props.isMobile ? "15px !important" : "24px !important",
            //marginLeft: { xs: 0, sm: 0, md: 1, lg: 1 },
          }}
          variant="outlined"
          size="large"
          onClick={handleFilterClick}
          startIcon={<FilterList />}
        >
          Filter
        </Button>
        {mobileFilterAnchorEl == null ? (
          <></>
        ) : (
          <FilterMobile
            id="filter-mobile"
            anchorEl={mobileFilterAnchorEl}
            open={mobileFilterOpen}
            handleClose={handleFilterClose}
            clear={props.clearFilters}
            schoolList={schoolList}
            positionFilters={props.positionFilters}
            handleCheckPosition={props.updatePositionFilters}
            classFilters={props.classFilters}
            handleCheckClass={props.updateClassFilters}
            confFilters={props.confFilters}
            handleCheckConf={props.updateConfFilters}
            stateFilters={props.stateFilters}
            handleCheckState={props.updateStateFilters}
            schoolFilters={props.schoolFilters}
            handleCheckSchool={props.updateSchoolFilters}
            savedPlayerFilter={props.savedPlayerFilter}
            handleCheckSavedPlayers={props.handleSavedPlayerFilterUpdate}
            secondaryFilters={props.secondaryFilters}
            updateRoleFilters={props.updateRoleFilters}
            roleFilters={props.roleFilters}
          />
        )}
        {mobileSavedAnchorEl == null ? (
          <></>
        ) : (
          <SavedMobile
            id="saved-mobile"
            anchorEl={mobileSavedAnchorEl}
            open={favoritesOpenMobile}
            handleClose={handleFavoritesClose}
            favoritesListItems={favoritesListItems}
            removeFavorite={props.removeFavorite}
            handleClick={handleClick}
            transferColor={transferColor}
          ></SavedMobile>
        )}
        <Menu
          sx={{ textAlign: "center" }}
          id="filter-menu"
          anchorEl={filterMenuAnchorEl}
          open={filterOpen}
          onClose={handleFilterClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          disableEnforceFocus
        >
          <MenuItem
            onMouseEnter={handlePositionMenuOpen}
            onMouseLeave={handlePositionMenuClose}
            sx={{ justifyContent: "space-between" }}
            id="position-filter"
          >
            Position
            <ArrowRight />
          </MenuItem>
          <MenuItem
            onMouseEnter={handleClassMenuOpen}
            onMouseLeave={handleClassMenuClose}
            sx={{ justifyContent: "space-between" }}
          >
            Class
            <ArrowRight />
          </MenuItem>
          <MenuItem
            onMouseEnter={handleSchoolMenuOpen}
            onMouseLeave={handleSchoolMenuClose}
            sx={{ justifyContent: "space-between" }}
          >
            School
            <ArrowRight />
          </MenuItem>
          <MenuItem
            onMouseEnter={handleConfMenuOpen}
            onMouseLeave={handleConfMenuClose}
            sx={{ justifyContent: "space-between" }}
          >
            Conference
            <ArrowRight />
          </MenuItem>
          <MenuItem
            onMouseEnter={handleStateMenuOpen}
            onMouseLeave={handleStateMenuClose}
            sx={{ justifyContent: "space-between" }}
          >
            Home State
            <ArrowRight />
          </MenuItem>
          <MenuItem
            onMouseEnter={handleRoleMenuOpen}
            onMouseLeave={handleRoleMenuClose}
            sx={{ justifyContent: "space-between" }}
          >
            Role
            <ArrowRight />
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "space-between" }}
            onClick={props.handleSavedPlayerFilterUpdate}
          >
            Saved Players
          </MenuItem>
          <Button
            sx={{ background: "white", mt: 1, mb: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={props.clearFilters}
          >
            Clear All
          </Button>
        </Menu>
        <FilterMenuPosition
          position={positionAnchorEl}
          display={positionAnchorEl ? "block" : "none"}
          handleMouseEnter={handlePositionMenuMouseEnter}
          handleMouseLeave={handlePositionMenuClose}
          positionFilters={props.positionFilters}
          handleCheckPosition={props.updatePositionFilters}
        />
        <FilterMenuSchool
          position={schoolAnchorEl}
          display={schoolAnchorEl ? "block" : "none"}
          handleMouseEnter={handleSchoolMenuMouseEnter}
          handleMouseLeave={handleSchoolMenuClose}
          selectedSchool={selectedSchool}
          setSelectedSchool={setSelectedSchool}
          schoolFilters={props.schoolFilters}
          schoolList={schoolList}
          handleCheckSchool={props.updateSchoolFilters}
        />
        <FilterMenuConference
          position={confAnchorEl}
          display={confAnchorEl ? "block" : "none"}
          handleMouseEnter={handleConfMenuMouseEnter}
          handleMouseLeave={handleConfMenuClose}
          confFilters={props.confFilters}
          handleCheckConf={props.updateConfFilters}
        />
        <FilterMenuState
          position={stateAnchorEl}
          display={stateAnchorEl ? "block" : "none"}
          handleMouseEnter={handleStateMenuMouseEnter}
          handleMouseLeave={handleStateMenuClose}
          stateFilters={props.stateFilters}
          handleCheckState={props.updateStateFilters}
        />
        <FilterMenuClass
          position={classAnchorEl}
          display={classAnchorEl ? "block" : "none"}
          handleMouseEnter={handleClassMenuMouseEnter}
          handleMouseLeave={handleClassMenuClose}
          classFilters={props.classFilters}
          handleCheckClass={props.updateClassFilters}
        />
        <FilterMenuRole
          position={roleAnchorEl}
          display={roleAnchorEl ? "block" : "none"}
          handleMouseEnter={handleRoleMenuMouseEnter}
          handleMouseLeave={handleRoleMenuClose}
          roleFilters={props.roleFilters}
          league={props.league}
          updateRoleFilters={props.updateRoleFilters}
        />
        <Button
          sx={{
            background: "white",
            mt: 3,
            marginRight: "8px !important",
            // ml: 1,
            paddingLeft: 1.5,
            paddingRight: 1.5,
            maxWidth: 180,
            alignItems: "center",
            marginTop: props.isMobile ? "15px !important" : "24px !important",
          }}
          style={
            props.sortModel.length === 0
              ? {
                  color: "#5B5B5B",
                  borderColor: "#ADADAD",
                }
              : {
                  color: sortingColor(
                    props.sortModel[0].field,
                    props.sortModel[0].sort === "asc",
                    false
                  ),
                  borderColor: sortingColor(
                    props.sortModel[0].field,
                    props.sortModel[0].sort === "asc",
                    true
                  ),
                }
          }
          variant="outlined"
          size="large"
          onClick={handleSortClick}
          startIcon={
            props.sortModel.length === 0 ? (
              <Sort />
            ) : (
              <ArrowDownward fontSize="small" />
            )
          }
        >
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
              whiteSpace: "nowrap",
            }}
          >
            {props.sortModel.length === 0
              ? "Sort"
              : props.sortModel[0].sort === "asc"
              ? sortingText(props.sortModel[0].field, true)
              : sortingText(props.sortModel[0].field, false)}
          </span>
        </Button>
        {mobileSortAnchorEl == null ? (
          <></>
        ) : (
          <SortMobile
            id="sort-mobile"
            anchorEl={mobileSortAnchorEl}
            open={mobileSortOpen}
            updateSort={props.updateSortModel}
            sortModel={props.sortModel}
            handleSort={handleSortClose}
          />
        )}
        <Menu
          sx={{ textAlign: "center" }}
          id="sort-menu"
          anchorEl={sortMenuAnchorEl}
          open={sortOpen}
          onClose={handleSortClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "scorer" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "scorer", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Scorers
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "shooter" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "shooter", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Shooters
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "finisher" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "finisher", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Finishers
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "playmaker" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "playmaker", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Playmakers
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "rebounder" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "rebounder", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Rebounders
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "defender" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "defender", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Defenders
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "rimprotector" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "rimprotector", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Rim Protectors
          </MenuItem>
          <MenuItem
            sx={
              props.sortModel.length > 0
                ? props.sortModel[0].field === "win share" &&
                  props.sortModel[0].sort === "desc"
                  ? { color: "rgb(47, 198, 138)" }
                  : ""
                : ""
            }
            onClick={() => {
              props.updateSortModel([{ field: "win share", sort: "desc" }]);
              handleSortClose();
            }}
          >
            <ArrowDownward fontSize="small" sx={{ mr: 1 }} />
            Best Win Share
          </MenuItem>
          <Button
            sx={{ background: "white", mt: 1, mb: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={() => {
              props.updateSortModel([]);
              handleSortClose();
            }}
          >
            Unsort
          </Button>
        </Menu>
        <Button
          className="saved-button"
          sx={{
            background: "white",
            mt: 3,
            // ml: 1,
            // marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
            mr: "15px !important",
            paddingLeft: 1.5,
            paddingRight: 1.5,
            color: "#BF5700",
            borderColor: "#BF5700",
            whiteSpace: "nowrap",
            marginTop: props.isMobile ? "15px !important" : "24px !important",
          }}
          variant="outlined"
          size="large"
          disabled={props.favoritesList.length === 0}
          onClick={handleFavoritesClick}
          startIcon={<Star sx={{ color: "#BF5700" }} />}
        >
          ({props.favoritesList.length}) SAVED
        </Button>
        {props.favoritesList.length > 0 && (
          <Menu
            sx={{
              textAlign: "center",
              "& .MuiMenu-paper": {
                maxHeight: "400px",
              },
            }}
            id="favorites-menu"
            anchorEl={favoritesMenuAnchorEl}
            open={favoritesOpen}
            onClose={handleFavoritesClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {favoritesListItems.map((item) => (
              <MenuItem key={item.id}>
                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() => handleClick(item)}
                  >
                    <RadioButtonChecked
                      fontSize="small"
                      sx={{ color: transferColor(item), marginRight: "5px" }}
                    />
                    <Typography
                      width="130px"
                      maxWidth="130px"
                      display="flex"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      width="30px"
                      marginRight="8px"
                      marginLeft="6px"
                      alignItems="center"
                    >
                      {item.pos}
                    </Typography>
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_BLOB_URL +
                        "/college-logos/" +
                        item.teamname +
                        ".jpg"
                      }
                      style={{
                        height: "20px",
                        marginRight: "8px",
                      }}
                    ></img>
                  </Box>
                  {/* <IconButton onClick={() => handleFavorites(item)}> */}
                  <IconButton
                    onClick={() => props.removeFavorite(item.playerid)}
                  >
                    <Clear sx={{ color: "#D32F2F" }} fontSize="small"></Clear>
                  </IconButton>
                </Box>
              </MenuItem>
            ))}
          </Menu>
        )}
        <FormControlLabel
          sx={{
            mt: 3,
            ml: 0,
            marginTop: props.isMobile ? "15px !important" : "24px !important",
            // marginLeft: { xs: 0, sm: 0, md: 1, lg: 1 },
          }}
          control={
            <IOSSwitch
              sx={{ m: 1, ml: 0 }}
              onChange={handleInPortalSwitch}
              checked={props.inPortalChecked}
              disabled={props.season != years[0]}
            />
          }
          label="Transfer Portal only"
        />
      </Container>
      <ActiveFiltersBar
        positionFilters={props.positionFilters}
        schoolFilters={props.schoolFilters}
        confFilters={props.confFilters}
        stateFilters={props.stateFilters}
        classFilters={props.classFilters}
        roleFilters={props.roleFilters}
        savedPlayerFilter={props.savedPlayerFilter}
        removePositionFilter={props.removePositionFilter}
        removeSchoolFilter={props.removeSchoolFilter}
        removeConfFilter={props.removeConfFilter}
        removeStateFilter={props.removeStateFilter}
        removeClassFilter={props.removeClassFilter}
        removeRoleFilter={props.removeRoleFilter}
        handleSavedPlayerFilterUpdate={props.handleSavedPlayerFilterUpdate}
      />
      <Card
        sx={{
          height: tableHeight,
          width: "100%",
          marginTop: "20px",
          padding: "0px",
        }}
      >
        <DataGrid
          sx={{
            "& .MuiTablePagination-selectLabel": {
              visibility: { xs: "hidden", sm: "hidden", md: "visible" },
              // visibility: props.isMobile ? "hidden" : "visible",
              overflow: props.isMobile ? "hidden" : "visible",
              width: props.isMobile ? "0px" : "auto",
              height: "auto",
            },
            "& .MuiTablePagination-input": {
              // visibility: "visible",
              visibility: { xs: "hidden", sm: "visible", md: "visible" },
              width: { xs: "0px", sm: "auto" },
              display: "flex",
              marginRight: props.isMobile ? "12px" : "32px",
            },
            "& .MuiTablePagination-toolbar": {
              height: "50px",
            },
          }}
          className="playerTable"
          rows={playersInTable}
          getRowId={(row) => row.playerid}
          columns={columns}
          initialState={{
            pinnedColumns: { left: ["Name"] },
          }}
          sortModel={props.sortModel}
          sortingOrder={["desc", "asc", null]}
          onSortModelChange={(newSortModel) =>
            props.updateSortModel(newSortModel)
          }
          getCellClassName={(params) => {
            if (
              params.field === "scorer" ||
              params.field === "shooter" ||
              params.field === "finisher" ||
              params.field === "playmaker" ||
              params.field === "rebounder" ||
              params.field === "defender"
            ) {
              if (params.value >= 80) {
                return "highScore";
              } else if (params.value <= 20) {
                return "lowScore";
              }
            } else if (params.field === "name") {
              if (params.row.portalStatus === "In Portal") {
                return "inPortal";
              }
            }
          }}
          // pagination props
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
          page={props.page}
          onPageChange={(newPage) => props.updatePageNum(newPage)}
          loading={loading}
          components={{ Footer: PlayerTableFooter }}
          componentsProps={{
            footer: { league: props.league, isMobile: props.isMobile },
          }}
        />
      </Card>
      <Typography
        sx={{
          textAlign: "right",
          width: "100%",
          mt: 1.5,
          opacity: "0.3",
          fontSize: "0.9rem",
          fontStyle: "italic",
          mr: 1,
        }}
      >
        Data refreshed 3/26/2024
      </Typography>
    </Container>
  );
}
