import * as React from "react";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Divider,
  Button,
  Box,
  TransitionComponent,
  Slide,
  TextField,
  Checkbox,
  Input,
  FormGroup,
} from "@mui/material";
import {
  ArrowRight,
  Clear,
  ArrowLeft,
  TextFormatSharp,
  AddCircleOutline,
} from "@mui/icons-material";
import RoleFilter from "./RoleFilter";
import { ClickAwayListener } from "@mui/base";
// import { MemoizedFilterChecklist } from './FilterChecklist'

export default function FilterMobileRole(props) {
  const [filterMenuHeight, setFilterMenuHeight] = React.useState(
    Boolean(props.anchorEl)
      ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
      : 600
  );

  React.useEffect(() => {
    setFilterMenuHeight(
      Boolean(props.anchorEl)
        ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
        : 600
    );
  }, [props.anchorEl]);

  const handleCloseClear = () => {
    props.clear();
    props.handleClose();
    props.handleCloseMain();
  };

  const handleCloseView = () => {
    props.handleClose();
    props.handleCloseMain();
  };

  const roles = [
    "scorer",
    "shooter",
    "finisher",
    "playmaker",
    "rebounder",
    "defender",
    "rimprotector",
  ];
  const rolesFormatted = [
    "Scorer",
    "Shooter",
    "Finisher",
    "Playmaker",
    "Rebounder",
    "Defender",
    "Rim Protector",
  ];

  const [keyCounter, setKeyCounter] = React.useState(0);

  const addFilter = () => {
    props.updateRoleFilters(keyCounter, 0, {
      key: keyCounter,
      role: "",
      operator: "greater_than",
      threshold: "80",
    });
    setKeyCounter(keyCounter + 1);
  };

  // remove any current filters from the list of available roles
  let availableRoles = [...roles];
  let availableRolesFormatted = [...rolesFormatted];
  props.roleFilters.map((filter, index) => {
    for (let i = 0; i < availableRoles.length; i++) {
      if (filter.role === availableRoles[i]) {
        availableRoles.splice(i, 1);
        availableRolesFormatted.splice(i, 1);
        break;
      }
    }
  });

  return (
    <div>
      <ClickAwayListener onClickAway={handleCloseView}>
        <Box
          sx={{
            //visibility: props.anchorEl == null ? "hidden" : "visibile",
            position: "fixed",
            top: "60px",
            left: "0",
            textAlign: "center",
            paddingTop: "12px",
            paddingBottom: "12px",
            width: "100%",
            height: "100%",
            background: "white",
            borderTop: 1,
            borderColor: "rgba(0, 0, 0, 0.20)",
            zIndex: "9998 !important",
            borderRadius: "15px",
            overflow: "auto",
          }}
        >
          <MenuItem divider="true" onClick={props.handleClose}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowLeft></ArrowLeft>
            </Box>
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              {props.title}
            </Box>
          </MenuItem>
          <Box>
            <FormGroup
              sx={{ pointerEvents: "auto" }}
              onClick={(event) => event.stopPropagation()}
            >
              {props.roleFilters.map((filter, index) => {
                const roleIndex = roles.indexOf(filter.role);
                let roleFormatted = "";
                if (roleIndex >= 0) {
                  roleFormatted = rolesFormatted[roleIndex];
                }
                return (
                  <RoleFilter
                    selectedFilterName={roleFormatted}
                    availableRoles={availableRoles}
                    availableRolesFormatted={availableRolesFormatted}
                    key={filter.key}
                    filter={filter}
                    updateRoleFilters={props.updateRoleFilters}
                  ></RoleFilter>
                );
              })}
              {props.roleFilters.length < 7 ? (
                <Button onClick={addFilter}>
                  <AddCircleOutline
                    sx={{ color: "#2FC68A", marginRight: "5px" }}
                  />
                  Add Role Filter
                </Button>
              ) : (
                ""
              )}
            </FormGroup>
          </Box>
        </Box>
      </ClickAwayListener>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          visibility: props.anchorEl == null ? "hidden" : "visibile",
          position: "fixed",
          bottom: "0",
          left: "0",
          alignContent: "center",
          textAlign: "center",
          justifyContent: "center",
          paddingTop: "12px",
          paddingBottom: "12px",
          width: "100%",
          background: "white",
          borderTop: 1,
          borderColor: "rgba(0, 0, 0, 0.20)",
          zIndex: "9999",
        }}
      >
        <Button
          sx={{ background: "white", mr: "12px" }}
          variant="outlined"
          size="small"
          startIcon={<Clear />}
          onClick={handleCloseClear}
        >
          Clear All
        </Button>
        <Button
          sx={{
            background: "white",
            color: "#BF5700",
            ml: "12px",
            borderColor: "#BF5700",
            whiteSpace: "nowrap",
          }}
          onClick={handleCloseView}
          variant="outlined"
          size="small"
        >
          VIEW RESULTS
        </Button>
      </Box>
    </div>
  );
}
