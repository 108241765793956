import { Box, Container } from "@mui/material";
import McCombsLogo from "../images/mccombs-logo.svg";
import AccentureLogo from "../images/accenture-logo.png";

export default function Footer(props) {
  return (
    <Box
      sx={{
        background: "#D9D9D9",
        zIndex: 1,
        marginTop: "50px",
        borderTop: "2px solid #787878",
        height: props.isMobile ? "auto" : "150px",
        paddingTop: props.isMobile ? "10px" : "20px",
        paddingBottom: props.isMobile ? "30px" : "0px",
      }}
    >
      <Container
        // maxWidth="lg"
        maxWidth="1284px"
        sx={{
          paddingLeft: props.isMobile ? "" : "48px !important",
          paddingRight: props.isMobile ? "" : "48px !important",
          maxWidth: "1284px",
        }}
      >
        <img
          src={McCombsLogo}
          style={{
            width: "60%",
            maxWidth: "332px",
            marginRight: "10px",
            marginTop: "5px",
          }}
        />
        <img
          src={AccentureLogo}
          style={{
            float: "right",
            width: "24%",
            maxWidth: "142px",
          }}
        />
      </Container>
    </Box>
  );
}
