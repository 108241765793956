import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import "./App.css";
import TransferIQ from "./components/TransferIQ";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import Bevo from "./images/Athletics_Bevo_Silhouette.svg";

const theme = createTheme({
  typography: {
    fontFamily: [
      //'Lato',
      //'Roboto',
      "Libre Franklin",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#5b5b5b",
    },
    secondary: {
      main: "#bf5700",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
          padding: "10px",
          marginTop: "5px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.04)",
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(0,0,0,0.08) !important",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "rgba(191, 67, 0, 0.12)",
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#5c5c5c !important",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#5c5c5c",
          },
        },
      },
    },
  },
});

const components = {
  Header() {
    return (
      <View
        textAlign="center"
        padding={"10px"}
        style={{ background: "#BF5700", marginTop: "20px" }}
      >
        <img
          src={Bevo}
          style={{
            height: "42px",
            fill: "#BF5700",
            display: "inline-block",
            marginRight: "10px",
            marginLeft: "-30px",
          }}
        />
        <Typography
          variant="h4"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            fontFamily: "Roboto",
            fontWeight: "700",
            lineHeight: "1em",
            marginTop: "6px",
            marginBottom: "1px",
            fontSize: "36px",
            color: "white",
            display: "inline-block",
          }}
        >
          TransferIQ
        </Typography>
      </View>
    );
  },
};

function App() {
  return (
    <Authenticator components={components} hideSignUp>
      {({ signOut }) => (
        <ThemeProvider theme={theme}>
          <BrowserRouter basename="/">
            <Routes>
              <Route
                path="/*"
                element={<TransferIQ theme={theme} signOutHandler={signOut} />}
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      )}
    </Authenticator>
  );
}

export default App;
