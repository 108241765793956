import * as React from "react";
import { Box, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";

function ActiveFiltersBar(props) {
  const formatRoleFilter = (filter) => {
    let op = "<";
    if (filter.operator === "greater_than") op = ">";
    if (filter.role === "rimprotector")
      return `Rim Protector ${op} ${filter.threshold}`;
    return `${filter.role} ${op} ${filter.threshold}`;
  };

  return (
    <Box>
      {props.positionFilters.map((item, index) => {
        return (
          <Button
            key={index}
            sx={{ background: "white", mt: 2, mr: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={() => props.removePositionFilter(item)}
          >
            {item}
          </Button>
        );
      })}
      {props.schoolFilters.map((item, index) => {
        return (
          <Button
            key={index}
            sx={{ background: "white", mt: 2, mr: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={() => props.removeSchoolFilter(item)}
          >
            {item}
          </Button>
        );
      })}
      {props.confFilters.map((item, index) => {
        return (
          <Button
            key={index}
            sx={{ background: "white", mt: 2, mr: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={() => props.removeConfFilter(item)}
          >
            {item}
          </Button>
        );
      })}
      {props.stateFilters.map((item, index) => {
        return (
          <Button
            key={index}
            sx={{ background: "white", mt: 2, mr: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={() => props.removeStateFilter(item)}
          >
            Home State: &nbsp;{item}
          </Button>
        );
      })}
      {props.classFilters.map((item, index) => {
        return (
          <Button
            key={index}
            sx={{ background: "white", mt: 2, mr: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Clear />}
            onClick={() => props.removeClassFilter(item)}
          >
            Class: &nbsp;{item}
          </Button>
        )
      })}
      {props.roleFilters.map((item, index) => {
        if (item.role && item.operator && item.threshold)
          return (
            <Button
              key={index}
              sx={{ background: "white", mt: 2, mr: 1 }}
              variant="outlined"
              size="small"
              startIcon={<Clear />}
              onClick={() => props.removeRoleFilter(index)}
            >
              {formatRoleFilter(item)}
            </Button>
          );
      })}
      {props.savedPlayerFilter === true && (
        <Button
          key="saved"
          sx={{ background: "white", mt: 2, mr: 1 }}
          variant="outlined"
          size="small"
          startIcon={<Clear />}
          onClick={props.handleSavedPlayerFilterUpdate}
        >
          SAVED
        </Button>
      )}
    </Box>
  );
}

export default ActiveFiltersBar;
