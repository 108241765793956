import * as React from 'react'
import { Box, Container } from '@mui/material'

export default function Tab(props) {

  let bgColor, textColor, fontSize, fontWeight, tabHeight, shadow
  bgColor = props.selected === true ? '#787878' : '#F4F4F4'
  textColor = props.selected === true ? '#fff' : '#000'
  fontSize = props.selected === true ? '1.1em' : '1em'
  fontWeight = props.selected === true ? 'bold' : 'unset'
  tabHeight = props.selected === true ? '40px' : '35px'
  shadow = props.selected === true ? 'none' : '-1px -2px 2px rgb(0 0 0 / 20%)'

  return (
    <Box onClick={props.clickHandler} sx={{ 
        height: tabHeight, 
        width: '109px',
        background: bgColor,
        color: textColor,
        fontSize: fontSize,
        fontWeight: fontWeight,
        borderRadius: '3px 3px 0px 0px',
        marginRight: '3px',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: shadow,
        alignItems: 'center' }}>
          {props.text}
    </Box>
  )

}