import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem !important",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.2rem !important",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1rem !important",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem !important",
  },
}));

function ProgressArc(props) {
  const [progress, setProgress] = React.useState(0);

  const worstColor = "rgb(254, 86, 52)";
  const badColor = "#ffb92c";
  const avgColor = "#ffe400";
  const goodColor = "#a7e38f";
  const bestColor = "rgb(47, 198, 138)";

  const reduction = 0.25;
  const strokeWidth = 4;
  const ballStrokeWidth = 16;
  const width = 200;
  const center = width / 2;
  const height = 190 || center + center * Math.cos(reduction * Math.PI);
  const rotate = 90 + 180 * reduction;
  const r = center - strokeWidth / 2 - ballStrokeWidth / 2;
  const circumference = Math.PI * r * 2;
  let finalValue = parseFloat(props.score);

  const getCurrentColor = () => {
    if (progress <= 20) {
      return worstColor;
    } else if (progress <= 40) {
      return badColor;
    } else if (progress <= 60) {
      return avgColor;
    } else if (progress <= 80) {
      return goodColor;
    } else {
      return bestColor;
    }
  };

  React.useLayoutEffect(() => {
    let currentStep = 0;
    let currentValue = parseFloat(props.previousScore);
    const transitionDuration = (3 * Math.abs(finalValue - currentValue)) / 100;
    const stepDuration = transitionDuration / 100;
    let totalSteps = 0;
    if (stepDuration > 0) {
      totalSteps = transitionDuration / stepDuration;
    }
    let stepSize = 0;
    if (totalSteps > 0) {
      stepSize = (finalValue - currentValue) / totalSteps;
    }

    const interval = setInterval(
      () =>
        setProgress((progress) => {
          currentStep++;
          if (currentStep >= totalSteps) {
            clearInterval(interval);
            return finalValue;
          }
          const pctDelta = currentStep * stepSize;
          let currentPct =
            currentValue + parseInt(Math.round(pctDelta * 100) / 100);
          if (isNaN(currentPct)) {
            currentPct = 0;
          }
          return currentPct;
        }),
      stepDuration * 1000
    );
    return () => clearInterval(interval);
  }, [finalValue]);

  return (
    <Box
      sx={{
        width: "30%",
        padding: "10px",
        "& .MuiTypography-h5": {
          fontSize: props.isMobile ? "13px" : "1.2rem",
        },
      }}
    >
      <svg viewBox={`0 0 ${width} ${height}`}>
        <defs></defs>
        <text
          x={center}
          y={center + 15}
          textAnchor="middle"
          fontSize="50px"
          fill={getCurrentColor()}
        >
          {isNaN(progress) ? 0 : progress}%
        </text>
        <circle // gray
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference}`}
          strokeDashoffset={circumference * reduction}
          fill="none"
          stroke={"#dde2e9"}
          strokeLinecap="round"
        ></circle>
        <circle // single color option
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={
            (circumference *
              (100 - (isNaN(progress) ? 0 : progress) * (1 - reduction))) /
            100
          }
          fill="none"
          stroke={getCurrentColor()}
          strokeLinecap="round"
        ></circle>

        <circle // ball
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={ballStrokeWidth}
          strokeDasharray={`1 ${circumference}`}
          strokeDashoffset={
            (circumference * (100 - progress * (1 - reduction))) / 100
          }
          fill="none"
          stroke={getCurrentColor()}
          strokeLinecap="round"
        ></circle>
      </svg>
      <StyledLabel
        variant="h5"
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          lineHeight: props.isMobile ? "1.2em" : "0px",
          fontWeight: "500",
          textWrap: "nowrap",
        }}
      >
        {props.roleName}
      </StyledLabel>
    </Box>
  );
}

export default ProgressArc;
