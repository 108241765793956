import * as React from "react";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Divider,
  Button,
  Box,
  TransitionComponent,
  Slide,
  Checkbox,
} from "@mui/material";
import { ArrowRight, Clear } from "@mui/icons-material";
import FilterMobileSecondary from "./FilterMobileSecondary";
import FilterMobileRole from "./FilterMobileRole";
import ConfData from "../data/conferences.json";
import StateData from "../data/states.json";
import { ClickAwayListener } from "@mui/base";

// import { MemoizedFilterChecklist } from './FilterChecklist'

const positions = ["G", "F", "C"];
const classes = ["FR", "SO", "JR", "SR", "Other"];

export default function FilterMobile(props) {
  // secondary menu
  const [filterSecondaryAnchorEl, setFilterSecondaryAnchorEl] =
    React.useState(null);
  const [filterRoleAnchorEl, setFilterRoleAnchorEl] = React.useState(null);
  const [secondaryData, setSecondaryData] = React.useState([]);
  const [secondaryTitle, setSecondaryTitle] = React.useState("");
  const [handleCheckSecondary, setHandleCheckSecondary] = React.useState();
  const [isSchool, setIsSchool] = React.useState(false);
  const filterSecondaryOpen = Boolean(filterSecondaryAnchorEl);
  const filterRoleOpen = Boolean(filterRoleAnchorEl);

  const handleCloseSecondary = () => {
    setFilterSecondaryAnchorEl(null);
    setIsSchool(false);
    setFilterRoleAnchorEl(null);
  };
  const handleFilterClick = (event) => {
    setFilterSecondaryAnchorEl(props.anchorEl);
  };
  const handleRoleClick = (event) => {
    setFilterRoleAnchorEl(props.anchorEl);
  };

  const handleFilterClickPosition = (event) => {
    setHandleCheckSecondary(() => props.handleCheckPosition);
    setSecondaryTitle("Positions");
    setSecondaryData(positions);
    handleFilterClick();
  };
  const handleFilterClickClasses = (event) => {
    setHandleCheckSecondary(() => props.handleCheckClass);
    setSecondaryTitle("Class");
    setSecondaryData(classes);
    handleFilterClick();
  };
  const handleFilterClickConf = (event) => {
    setHandleCheckSecondary(() => props.handleCheckConf);
    setSecondaryTitle("Conference");
    setSecondaryData(ConfData);
    handleFilterClick();
  };
  const handleFilterClickState = (event) => {
    setHandleCheckSecondary(() => props.handleCheckState);
    setSecondaryTitle("Home State");
    setSecondaryData(StateData);
    handleFilterClick();
  };
  const handleFilterClickSchool = (event) => {
    setHandleCheckSecondary(() => props.handleCheckSchool);
    setSecondaryTitle("School");
    setSecondaryData(props.schoolList);
    setIsSchool(true);
    handleFilterClick();
  };
  const handleFilterClickRole = (event) => {
    setHandleCheckSecondary(() => props.handleCheckRole);
    setSecondaryTitle("Role");
    handleRoleClick();
  };

  const [filterMenuHeight, setFilterMenuHeight] = React.useState(
    Boolean(props.anchorEl)
      ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
      : 600
  );

  React.useEffect(() => {
    console.log("opening menu");
    setFilterMenuHeight(
      Boolean(props.anchorEl)
        ? window.innerHeight - props.anchorEl.getBoundingClientRect().top - 12
        : 600
    );
  }, [props.anchorEl]);

  const handleCloseClear = () => {
    props.clear();
    props.handleClose();
  };

  const handleClose = () => {
    if (filterSecondaryAnchorEl == null && filterRoleAnchorEl == null) {
      props.handleClose();
    }
    console.log(filterRoleAnchorEl);
  };

  let clickAwayListenerActive =
    filterSecondaryAnchorEl == null && filterRoleAnchorEl == null
      ? true
      : false;
  return (
    <div>
      <ClickAwayListener
        onClickAway={props.handleClose}
        touchEvent={
          filterSecondaryAnchorEl == null && filterRoleAnchorEl == null
            ? "onTouchEnd"
            : false
        }
        mouseEvent={
          filterSecondaryAnchorEl == null && filterRoleAnchorEl == null
            ? "onClick"
            : false
        }
      >
        <div>
          <Box
            sx={{
              //visibility: props.anchorEl == null ? "hidden" : "visibile",
              position: "fixed",
              top: "60px",
              left: "0",
              textAlign: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              height: "100%",
              background: "white",
              borderTop: 1,
              borderColor: "rgba(0, 0, 0, 0.20)",
              zIndex: "9997 !important",
              borderRadius: "15px",
              overflow: "auto",
            }}
          >
            <MenuItem
              divider="true"
              disabled="true"
              sx={{
                alignContent: "center",
                textAlign: "center",
                justifyContent: "center",
                color: "black",
                opacity: "1 !important",
              }}
            >
              Filters
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ justifyContent: "space-between" }}
              id="position-filter"
              key="position"
              onClick={handleFilterClickPosition}
            >
              Position
              <ArrowRight />
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ justifyContent: "space-between" }}
              id="class-filter"
              onClick={handleFilterClickClasses}
            >
              Class
              <ArrowRight />
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ justifyContent: "space-between" }}
              id="school-filter"
              onClick={handleFilterClickSchool}
            >
              School
              <ArrowRight />
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ justifyContent: "space-between" }}
              id="conference-filter"
              onClick={handleFilterClickConf}
            >
              Conference
              <ArrowRight />
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ justifyContent: "space-between" }}
              id="state-filter"
              onClick={handleFilterClickState}
            >
              Home State
              <ArrowRight />
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ justifyContent: "space-between" }}
              id="role-filter"
              onClick={handleFilterClickRole}
            >
              Role
              <ArrowRight />
            </MenuItem>
            <MenuItem
              divider="true"
              sx={{ paddingLeft: "12px !important" }}
              id="saved-filter"
              onClick={props.handleCheckSavedPlayers}
            >
              <Checkbox
                checked={props.savedPlayerFilter}
                disableRipple
                sx={{
                  paddingTop: "0px !important",
                  paddingBottom: "0px !important",
                  paddingLeft: "0px !important",
                }}
              ></Checkbox>
              Saved Players
            </MenuItem>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              visibility: props.anchorEl == null ? "hidden" : "visibile",
              position: "fixed",
              bottom: "0",
              left: "0",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              background: "white",
              borderTop: 1,
              borderColor: "rgba(0, 0, 0, 0.20)",
              zIndex: "9999 !important",
            }}
          >
            <Button
              sx={{ background: "white", mr: "12px" }}
              variant="outlined"
              size="small"
              startIcon={<Clear />}
              onClick={handleCloseClear}
            >
              Clear All
            </Button>
            <Button
              sx={{
                background: "white",
                color: "#BF5700",
                ml: "12px",
                borderColor: "#BF5700",
                whiteSpace: "nowrap",
              }}
              onClick={props.handleClose}
              variant="outlined"
              size="small"
            >
              VIEW RESULTS
            </Button>
          </Box>
        </div>
      </ClickAwayListener>
      {filterSecondaryAnchorEl == null ? (
        <></>
      ) : (
        <FilterMobileSecondary
          id="filter-mobile"
          anchorEl={filterSecondaryAnchorEl}
          open={filterSecondaryOpen}
          handleClose={handleCloseSecondary}
          handleCloseMain={props.handleClose}
          clear={props.clear}
          listData={secondaryData}
          title={secondaryTitle}
          isSchool={isSchool}
          filter={props.secondaryFilters}
          handleCheckItem={handleCheckSecondary}
        ></FilterMobileSecondary>
      )}
      {filterRoleAnchorEl == null ? (
        <></>
      ) : (
        <FilterMobileRole
          id="filter-role"
          anchorEl={filterRoleAnchorEl}
          open={filterRoleOpen}
          handleClose={handleCloseSecondary}
          handleCloseMain={props.handleClose}
          clear={props.clear}
          title={secondaryTitle}
          isSchool={isSchool}
          filter={props.secondaryFilters}
          handleCheckItem={handleCheckSecondary}
          updateRoleFilters={props.updateRoleFilters}
          roleFilters={props.roleFilters}
        ></FilterMobileRole>
      )}
    </div>
  );
}
