import * as React from 'react'
import { Box } from '@mui/material'
import { MemoizedFilterChecklist } from './FilterChecklist'
import StateData from '../data/states.json'

export default function FilterMenuState(props) {
  return (
    <Box id="state-menu"
      sx={{
        position: 'fixed',
        top: props.position ? props.position.getBoundingClientRect().top : 0,
        left: props.position ? props.position.getBoundingClientRect().right : 0,
        display: props.display,
        maxHeight: '400px',
        zIndex: '2000',
        background: 'white',
        overflowY: 'scroll',
        width: 'max-content',
        borderRadius: '4px',
        boxShadow: '1px 1px 10px rgb(0 0 0 / 35%)'
      }}
      onMouseEnter={props.handleMouseEnter}
      onMouseLeave={props.handleMouseLeave}
    >
      <MemoizedFilterChecklist filter={props.stateFilters} listData={StateData} handleCheckItem={props.handleCheckState} />
    </Box>
  )
}

