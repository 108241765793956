import * as React from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ModalBio from "./ModalBio";
import ModalProgressBar from "./ModalProgressBar";
import { ClickAwayListener } from "@mui/base";

function ModalComponent({ props, open, handleClose, anchorEl }) {
  const [showPlayer1, setShowPlayer1] = React.useState(true);
  const [showPlayer2, setShowPlayer2] = React.useState(false);

  const [player1, setPlayer1] = React.useState({
    headshotPath: props.headshotPath,
    league: props.league,
    status: props.status,
    name: props.name,
    logoPath: props.logoPath,
    school: props.school,
    pos: props.pos,
    year: props.year,
    height: props.height,
    weight: props.weight,
    season: props.season,
    playerId: props.playerId,
    history: props.history,
  });

  const [player2, setPlayer2] = React.useState({
    headshotPath:
      process.env.REACT_APP_STORAGE_BLOB_URL +
      "/" +
      props.league.substring(0, 1) +
      "-headshots/silhouette.png",
    league: props.league,
    status: "",
    name: "",
    logoPath: "",
    school: "",
    pos: "",
    year: "",
    height: "",
    weight: "",
    season: "",
    playerId: "",
    history: "",
  });

  const [scorerScore1, setScorerScore1] = React.useState(props.scorerScore);
  const [shooterScore1, setShooterScore1] = React.useState(props.shooterScore);
  const [finisherScore1, setFinisherScore1] = React.useState(
    props.finisherScore
  );
  const [playmakerScore1, setPlaymakerScore1] = React.useState(
    props.playmakerScore
  );
  const [rebounderScore1, setRebounderScore1] = React.useState(
    props.rebounderScore
  );
  const [defenderScore1, setDefenderScore1] = React.useState(
    props.defenderScore
  );
  const [rimprotectorScore1, setRimprotectorScore1] = React.useState(
    props.rimprotectorScore
  );

  const [scorerScore2, setScorerScore2] = React.useState(null);
  const [shooterScore2, setShooterScore2] = React.useState(null);
  const [finisherScore2, setFinisherScore2] = React.useState(null);
  const [playmakerScore2, setPlaymakerScore2] = React.useState(null);
  const [rebounderScore2, setRebounderScore2] = React.useState(null);
  const [defenderScore2, setDefenderScore2] = React.useState(null);
  const [rimprotectorScore2, setRimprotectorScore2] = React.useState(null);

  const resetValuesToOriginalProps = () => {
    setScorerScore1(props.scorerScore);
    setShooterScore1(props.shooterScore);
    setFinisherScore1(props.finisherScore);
    setPlaymakerScore1(props.playmakerScore);
    setRebounderScore1(props.rebounderScore);
    setDefenderScore1(props.defenderScore);
    setRimprotectorScore1(props.rimprotectorScore);

    setScorerScore2(null);
    setShooterScore2(null);
    setFinisherScore2(null);
    setPlaymakerScore2(null);
    setRebounderScore2(null);
    setDefenderScore2(null);
    setRimprotectorScore2(null);
  };

  const updateShowPlayer1 = (newVisibility) => {
    setShowPlayer1(newVisibility);
  };

  const updateShowPlayer2 = (newVisibility) => {
    setShowPlayer2(newVisibility);
  };

  const updatePlayer1 = (newPlayer) => {
    setPlayer1((prevState) => {
      return {
        ...prevState,
        headshotPath: newPlayer.headshotPath,
        league: newPlayer.league,
        status: newPlayer.status,
        name: newPlayer.name,
        logoPath: newPlayer.logoPath,
        school: newPlayer.school,
        pos: newPlayer.pos,
        year: newPlayer.year,
        height: newPlayer.height,
        weight: newPlayer.weight,
        season: newPlayer.season,
        playerId: newPlayer.playerId,
        history: newPlayer.history,
      };
    });
  };

  const updatePlayer2 = (newPlayer) => {
    setPlayer2((prevState) => {
      return {
        ...prevState,
        headshotPath: newPlayer.headshotPath,
        league: newPlayer.league,
        status: newPlayer.status,
        name: newPlayer.name,
        logoPath: newPlayer.logoPath,
        school: newPlayer.school,
        pos: newPlayer.pos,
        year: newPlayer.year,
        height: newPlayer.height,
        weight: newPlayer.weight,
        season: newPlayer.season,
        playerId: newPlayer.playerId,
        history: newPlayer.history,
      };
    });
  };

  useEffect(() => {
    setScorerScore1(props.scorerScore);
    setShooterScore1(props.shooterScore);
    setFinisherScore1(props.finisherScore);
    setPlaymakerScore1(props.playmakerScore);
    setRebounderScore1(props.rebounderScore);
    setDefenderScore1(props.defenderScore);
    setRimprotectorScore1(props.rimprotectorScore);
  }, [
    props.scorerScore,
    props.shooterScore,
    props.finisherScore,
    props.playmakerScore,
    props.rebounderScore,
    props.defenderScore,
    props.rimprotectorScore,
  ]);

  useEffect(() => {
    setScorerScore2(props.scorerScore2);
    setShooterScore2(props.shooterScore2);
    setFinisherScore2(props.finisherScore2);
    setPlaymakerScore2(props.playmakerScore2);
    setRebounderScore2(props.rebounderScore2);
    setDefenderScore2(props.defenderScore2);
    setRimprotectorScore2(props.rimprotectorScore2);
  }, [
    props.scorerScore2,
    props.shooterScore2,
    props.finisherScore2,
    props.playmakerScore2,
    props.rebounderScore2,
    props.defenderScore2,
    props.rimprotectorScore2,
  ]);

  // Effect to reset values when props change
  useEffect(() => {
    resetValuesToOriginalProps();
  }, [
    props.scorerScore,
    props.shooterScore,
    props.finisherScore,
    props.playmakerScore,
    props.rebounderScore,
    props.defenderScore,
    props.rimprotectorScore,
  ]);

  useEffect(() => {
    props.isMobile
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [props.isMobile]);

  return (
    <>
      {!props.isMobile ? (
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
            resetValuesToOriginalProps();
          }}
          fullWidth={true}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "1000px",
            },
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <ModalBio
                {...props}
                show={showPlayer1}
                player={player1}
                updatePlayer={updatePlayer1}
                updateVisibility={updateShowPlayer1}
                searchPlayerList={props.searchPlayerList}
                updateScorerScore={setScorerScore1}
                updateShooterScore={setShooterScore1}
                updateFinisherScore={setFinisherScore1}
                updatePlaymakerScore={setPlaymakerScore1}
                updateRebounderScore={setRebounderScore1}
                updateDefenderScore={setDefenderScore1}
                updateRimprotectorScore={setRimprotectorScore1}
              ></ModalBio>
              <ModalBio
                {...props}
                show={showPlayer2}
                player={player2}
                updatePlayer={updatePlayer2}
                updateVisibility={updateShowPlayer2}
                searchPlayerList={props.searchPlayerList}
                updateScorerScore={setScorerScore2}
                updateShooterScore={setShooterScore2}
                updateFinisherScore={setFinisherScore2}
                updatePlaymakerScore={setPlaymakerScore2}
                updateRebounderScore={setRebounderScore2}
                updateDefenderScore={setDefenderScore2}
                updateRimprotectorScore={setRimprotectorScore2}
              ></ModalBio>
            </Box>
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Scorer"
              playerScore={scorerScore1}
              playerScore2={scorerScore2}
            />
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Shooter"
              playerScore={shooterScore1}
              playerScore2={shooterScore2}
            />
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Finisher"
              playerScore={finisherScore1}
              playerScore2={finisherScore2}
            />
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Playmaker"
              playerScore={playmakerScore1}
              playerScore2={playmakerScore2}
            />
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Rebounder"
              playerScore={rebounderScore1}
              playerScore2={rebounderScore2}
            />
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Defender"
              playerScore={defenderScore1}
              playerScore2={defenderScore2}
            />
            <ModalProgressBar
              isMobile={props.isMobile}
              roleName="Rim Protector"
              playerScore={rimprotectorScore1}
              playerScore2={rimprotectorScore2}
            />
          </DialogContent>
          <DialogActions sx={{ marginTop: "5px", paddingTop: "0px" }}>
            <Button
              onClick={() => {
                handleClose();
                resetValuesToOriginalProps();
              }}
              color="primary"
              variant="outlined"
              size="small"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <div>
          <ClickAwayListener
            onClickAway={() => {
              handleClose();
              resetValuesToOriginalProps();
            }}
          >
            <div>
              <Box
                sx={{
                  position: "fixed",
                  top: "60px",
                  left: "0",
                  textAlign: "center",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  width: "100%",
                  height: "calc(100% - 60px - 79.15px)", // minus header and footer
                  background: "white",
                  borderTop: 1,
                  borderColor: "rgba(0, 0, 0, 0.20)",
                  zIndex: "999 !important",
                  borderRadius: "15px 15px 0px 0px",
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <ModalBio
                    {...props}
                    show={showPlayer1}
                    player={player1}
                    updatePlayer={updatePlayer1}
                    updateVisibility={updateShowPlayer1}
                    updateScorerScore={setScorerScore1}
                    updateShooterScore={setShooterScore1}
                    updateFinisherScore={setFinisherScore1}
                    updatePlaymakerScore={setPlaymakerScore1}
                    updateRebounderScore={setRebounderScore1}
                    updateDefenderScore={setDefenderScore1}
                    updateRimprotectorScore={setRimprotectorScore1}
                  ></ModalBio>

                  <ModalBio
                    {...props}
                    show={showPlayer2}
                    player={player2}
                    updatePlayer={updatePlayer2}
                    updateVisibility={updateShowPlayer2}
                    updateScorerScore={setScorerScore2}
                    updateShooterScore={setShooterScore2}
                    updateFinisherScore={setFinisherScore2}
                    updatePlaymakerScore={setPlaymakerScore2}
                    updateRebounderScore={setRebounderScore2}
                    updateDefenderScore={setDefenderScore2}
                    updateRimprotectorScore={setRimprotectorScore2}
                  ></ModalBio>
                </Box>
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Scorer"
                  playerScore={scorerScore1}
                  playerScore2={scorerScore2}
                />
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Shooter"
                  playerScore={shooterScore1}
                  playerScore2={shooterScore2}
                />
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Finisher"
                  playerScore={finisherScore1}
                  playerScore2={finisherScore2}
                />
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Playmaker"
                  playerScore={playmakerScore1}
                  playerScore2={playmakerScore2}
                />
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Rebounder"
                  playerScore={rebounderScore1}
                  playerScore2={rebounderScore2}
                />
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Defender"
                  playerScore={defenderScore1}
                  playerScore2={defenderScore2}
                />
                <ModalProgressBar
                  isMobile={props.isMobile}
                  roleName="Rim Protector"
                  playerScore={rimprotectorScore1}
                  playerScore2={rimprotectorScore2}
                />
              </Box>
              <Box
                display="block"
                sx={{
                  position: "fixed",
                  bottom: "0",
                  left: "0",
                  textAlign: "center",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  width: "100%",
                  background: "white",
                  borderTop: 1,
                  borderColor: "rgba(0, 0, 0, 0.20)",
                  zIndex: 9999,
                }}
              >
                <Button
                  sx={{ background: "white" }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    handleClose();
                    resetValuesToOriginalProps();
                  }}
                >
                  Close
                </Button>
              </Box>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
}

export default ModalComponent;
