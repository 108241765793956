import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Box, Container, CircularProgress } from "@mui/material";
import Bio from "./Bio";
import PlayerSummary from "./PlayerSummary";
import KeyStats from "./KeyStats";
import BlockHeader from "./BlockHeader";
import RoleSummary from "./RoleSummary";
import ShotChartControls from "./ShotChartControls";
import CoachNotes from "./CoachNotes";
import axios from "axios";
import PlayerPageTabBar from "./PlayerPageTabBar";
import duplicateNamesMen from "../../src/data/duplicate-names-men.json";
import duplicateNamesWomen from "../../src/data/duplicate-names-women.json";

export default function PlayerPage(props) {
  const navigate = useNavigate();
  const [boxscoreData, setBoxscoreData] = React.useState([]);
  const [shotData, setShotData] = React.useState([]);
  const [previousScores, setPreviousScores] = React.useState({
    prevScorerScore: 50,
    prevShooterScore: 50,
    prevFinisherScore: 50,
    prevPlaymakerScore: 50,
    prevRebounderScore: 50,
    prevDefenderScore: 50,
    prevRimprotectorScore: 50,
  });

  const [loaded, setLoaded] = React.useState(false);

  const buildPortalPlayersList = async (player, league) => {
    // Default all players to not in portal
    player.portalStatus = "Not in Portal";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_STORAGE_BLOB_URL}/${league.substring(
          0,
          1
        )}-portal-list/portal-players.json`
      );
      const portalJSON = await response.json();
      for (let i = 0; i < portalJSON.length; i++) {
        if (
          player.name === portalJSON[i].name_synergy &&
          player.teamname === portalJSON[i].school_synergy
        ) {
          // if (league === "mens") {
          //   player.portalStatus = portalJSON[i].portal_status;
          //   break;
          // } else if (league === "womens") {
          //   player.portalStatus = "In Portal";
          //   break;
          // }
          player.portalStatus = portalJSON[i].portal_status;
          break;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const selectPlayerFromUrl = async () => {
    const url = window.location.href;
    const regex = /\/player\/([^\/]+)\/([^\/]+)\/([^\/]+)/;
    const match = url.match(regex);

    //path="/player/:league/:season/:playerid"
    const league = match[1];
    const season = match[2];
    const playerId = match[3];

    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playerdata?league=${league}&playerid=${playerId}&season=${season}`
    );
    //setBoxscoreData(data.data.boxscore);

    const currentData = data.data.boxscore;
    await buildPortalPlayersList(currentData, league);
    props.updateSelectedLeague(league);
    props.updateSelectedPlayer(
      playerId,
      season,
      currentData.history,
      currentData.portalStatus
    );
    setLoaded(true);
  };

  const fetchBoxscoreData = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/playerdata?league=${props.league}&playerid=${props.playerId}&season=${props.season}`
    );
    setBoxscoreData(data.data.boxscore);

    const currentData = data.data.boxscore;

    if (props.loadingFromSearch) {
      await buildPortalPlayersList(currentData, props.league);
      props.updateSelectedPlayer(
        props.playerId,
        props.season,
        currentData.history,
        currentData.portalStatus
      );
    }

    setLoaded(true); // render now before updating firstRender
    props.setLoadingFromSearch(false);

    if (props.firstRender) {
      props.updateFirstRender(false);
    }
    updatePreviousScores(
      Math.round(currentData.scorer),
      Math.round(currentData.shooter),
      Math.round(currentData.finisher),
      Math.round(currentData.playmaker),
      Math.round(currentData.rebounder),
      Math.round(currentData.defender),
      Math.round(currentData.rimprotector)
    );
  };

  const fetchSeasonShotData = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_FLASK_URL}/seasonshots?league=${props.league}&playerid=${props.playerId}&season=${props.season}`
    );
    setShotData(data.data.shots);
  };

  const updatePreviousScores = (
    newScorerScore,
    newShooterScore,
    newFinisherScore,
    newPlaymakerScore,
    newRebounderScore,
    newDefenderScore,
    newRimprotectorScore
  ) => {
    setPreviousScores((prevState) => {
      return {
        ...prevState,
        prevScorerScore: newScorerScore,
        prevShooterScore: newShooterScore,
        prevFinisherScore: newFinisherScore,
        prevPlaymakerScore: newPlaymakerScore,
        prevRebounderScore: newRebounderScore,
        prevDefenderScore: newDefenderScore,
        prevRimprotectorScore: newRimprotectorScore,
      };
    });
  };

  React.useEffect(() => {
    if (props.playerId === null) {
      setLoaded(false);
      selectPlayerFromUrl();
    } else {
      if (props.loadingFromSearch) {
        setLoaded(false);
      }
      fetchBoxscoreData();
      fetchSeasonShotData();
    }
  }, [props.league, props.playerId, props.season]);

  React.useEffect(() => {
    const handlePopstate = () => {
      setLoaded(false);
      selectPlayerFromUrl();
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Get stats from shot chart data when possible
  let makes2pt = 0,
    att2pt = 0;
  let makes3pt = 0,
    att3pt = 0;

  for (let i = 0; i < shotData.length; i++) {
    if (shotData[i].is3pt === "Yes") {
      att3pt++;
      if (shotData[i].outcome === "Make") {
        makes3pt++;
      }
    } else {
      att2pt++;
      if (shotData[i].outcome === "Make") {
        makes2pt++;
      }
    }
  }

  let attAll = att2pt + att3pt;
  let makesAll = makes2pt + makes3pt;

  let fgPct = ((makesAll / attAll) * 100).toFixed(1);
  if (attAll === 0) {
    fgPct = "";
  }

  let ftPct = ((boxscoreData.ftmade / boxscoreData.ftattempt) * 100).toFixed(1);
  if (boxscoreData.ftattempt === 0) {
    ftPct = "";
  }

  let twoPtPct = ((makes2pt / att2pt) * 100).toFixed(1);
  if (att2pt === 0) {
    twoPtPct = "";
  }

  let threePtPct = ((makes3pt / att3pt) * 100).toFixed(1);
  if (att3pt === 0) {
    threePtPct = "";
  }

  // Hacky fix for shared headshots
  let headshotPath = `${
    process.env.REACT_APP_STORAGE_BLOB_URL
  }/${props.league.substring(0, 1)}-headshots/${boxscoreData.headshotName}.jpg`;
  let duplicateNamesList;
  if (props.league === "mens") {
    duplicateNamesList = duplicateNamesMen;
  } else {
    duplicateNamesList = duplicateNamesWomen;
  }
  for (let i = 0; i < duplicateNamesList.length; i++) {
    if (duplicateNamesList[i].name_bb_ref === boxscoreData.headshotName) {
      headshotPath = `${
        process.env.REACT_APP_STORAGE_BLOB_URL
      }/${props.league.substring(0, 1)}-headshots/${
        boxscoreData.headshotName
      } ${boxscoreData.teamname}.jpg`;
    }
  }

  return (
    <>
      {loaded ? (
        <div>
          <PlayerPageTabBar
            history={props.history}
            playerId={props.playerId}
            season={props.season}
            status={props.status}
            updateSelectedPlayer={props.updateSelectedPlayer}
            isMobile={props.isMobile}
          ></PlayerPageTabBar>
          <Container maxWidth="lg">
            <Box
              m={3}
              sx={{
                marginLeft: props.isMobile ? "0px" : "",
                marginRight: props.isMobile ? "0px" : "",
                "& .MuiTypography-body1": {
                  fontSize: props.isMobile ? "12px" : "1rem",
                },
              }}
            >
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} md={7}>
                  <Bio
                    isMobile={props.isMobile}
                    name={boxscoreData.name}
                    playerId={props.playerId}
                    school={boxscoreData.teamname}
                    favoritesListIncluded={props.favoritesListIncluded}
                    addFavorite={props.addFavorite}
                    removeFavorite={props.removeFavorite}
                    pos={boxscoreData.pos}
                    year={boxscoreData.playerclass}
                    height={boxscoreData.height}
                    weight={
                      boxscoreData.weight != 0
                        ? boxscoreData.weight + " lb"
                        : ""
                    }
                    history={props.history}
                    headshotPath={headshotPath}
                    logoPath={
                      process.env.REACT_APP_STORAGE_BLOB_URL +
                      "/college-logos/" +
                      boxscoreData.teamname +
                      ".jpg"
                    }
                    status={props.status}
                    hometown={boxscoreData.hometown}
                    highSchool={boxscoreData.highschool}
                    league={props.league}
                    season={props.season}
                    searchPlayerList={props.searchPlayerList}
                    srURL={boxscoreData.sportsRefUrl}
                    scorerScore={Math.round(boxscoreData.scorer)}
                    previousScorerScore={
                      props.firstRender ? 50 : previousScores.prevScorerScore
                    }
                    shooterScore={Math.round(boxscoreData.shooter)}
                    previousShooterScore={
                      props.firstRender ? 50 : previousScores.prevShooterScore
                    }
                    finisherScore={Math.round(boxscoreData.finisher)}
                    previousFinisherScore={
                      props.firstRender ? 50 : previousScores.prevFinisherScore
                    }
                    playmakerScore={Math.round(boxscoreData.playmaker)}
                    previousPlaymakerScore={
                      props.firstRender ? 50 : previousScores.prevPlaymakerScore
                    }
                    rebounderScore={Math.round(boxscoreData.rebounder)}
                    previousRebounderScore={
                      props.firstRender ? 50 : previousScores.prevRebounderScore
                    }
                    defenderScore={Math.round(boxscoreData.defender)}
                    previousDefenderScore={
                      props.firstRender ? 50 : previousScores.prevDefenderScore
                    }
                    rimprotectorScore={Math.round(boxscoreData.rimprotector)}
                    previousRimprotectorScore={
                      props.firstRender
                        ? 50
                        : previousScores.prevRimprotectorScore
                    }
                    headerMenuOpen={props.headerMenuOpen}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <PlayerSummary
                    scorerScore={Math.round(boxscoreData.scorer)}
                    previousScorerScore={
                      props.firstRender ? 50 : previousScores.prevScorerScore
                    }
                    shooterScore={Math.round(boxscoreData.shooter)}
                    previousShooterScore={
                      props.firstRender ? 50 : previousScores.prevShooterScore
                    }
                    finisherScore={Math.round(boxscoreData.finisher)}
                    previousFinisherScore={
                      props.firstRender ? 50 : previousScores.prevFinisherScore
                    }
                    playmakerScore={Math.round(boxscoreData.playmaker)}
                    previousPlaymakerScore={
                      props.firstRender ? 50 : previousScores.prevPlaymakerScore
                    }
                    rebounderScore={Math.round(boxscoreData.rebounder)}
                    previousRebounderScore={
                      props.firstRender ? 50 : previousScores.prevRebounderScore
                    }
                    defenderScore={Math.round(boxscoreData.defender)}
                    previousDefenderScore={
                      props.firstRender ? 50 : previousScores.prevDefenderScore
                    }
                    rimprotectorScore={Math.round(boxscoreData.rimprotector)}
                    previousRimprotectorScore={
                      props.firstRender
                        ? 50
                        : previousScores.prevRimprotectorScore
                    }
                  />
                </Grid>
                {props.isMobile && (
                  <Grid
                    item
                    xs={12}
                    md={5.5}
                    container
                    justifyContent="flex-end"
                    alignContent="flex-start"
                  >
                    <KeyStats
                      isMobile={props.isMobile}
                      season={props.season}
                      ppg={(
                        boxscoreData.points / boxscoreData.gamesplayed
                      ).toFixed(1)}
                      rpg={(
                        boxscoreData.totalreb / boxscoreData.gamesplayed
                      ).toFixed(1)}
                      apg={(
                        boxscoreData.assists / boxscoreData.gamesplayed
                      ).toFixed(1)}
                      fgpct={fgPct}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={6.5}
                  sx={{
                    "& .MuiPaper-root": {
                      //fontSize: props.isMobile ? "12px" : "1rem",
                      padding: props.isMobile ? "0px" : "10px",
                      paddingBottom: props.isMobile ? "10px" : "20px",
                    },
                  }}
                >
                  <BlockHeader text="Notes"></BlockHeader>
                  <CoachNotes
                    addNote={props.addNote}
                    srURL={boxscoreData.sportsRefUrl}
                    username={props.username}
                    isMobile={props.isMobile}
                  ></CoachNotes>
                  <BlockHeader text="Role Analysis"></BlockHeader>
                  <RoleSummary
                    roleName="Scorer"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.scorer)}
                    previousScore={
                      props.firstRender ? 0 : previousScores.prevScorerScore
                    }
                    stat1Name="PTS"
                    stat2Name="FG%"
                    stat3Name="3P%"
                    stat4Name="FT%"
                    stat5Name="2FG%"
                    stat6Name="TOV"
                    stat7Name="PPP"
                    stat8Name="POSS"
                    stat1Value={boxscoreData.points}
                    stat2Value={fgPct}
                    stat3Value={threePtPct}
                    stat4Value={ftPct}
                    stat5Value={twoPtPct}
                    stat6Value={boxscoreData.turnovers}
                    stat7Value={parseFloat(boxscoreData.ppp).toFixed(2)}
                    stat8Value={boxscoreData.possessions}
                  />
                  <RoleSummary
                    roleName="Shooter"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.shooter)}
                    previousScore={
                      props.firstRender ? 0 : previousScores.prevShooterScore
                    }
                    stat1Name="3P%"
                    stat2Name="FT%"
                    stat3Name="PPP"
                    stat4Name="POSS"
                    stat1Value={threePtPct}
                    stat2Value={ftPct}
                    stat3Value={parseFloat(boxscoreData.ppp).toFixed(2)}
                    stat4Value={boxscoreData.possessions}
                  />
                  <RoleSummary
                    roleName="Finisher"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.finisher)}
                    previousScore={
                      props.firstRender ? 0 : previousScores.prevFinisherScore
                    }
                    stat1Name=""
                    stat2Name="2FG%"
                    stat3Name="PPP"
                    stat4Name="POSS"
                    stat1Value=""
                    stat2Value={twoPtPct}
                    stat3Value={parseFloat(boxscoreData.ppp).toFixed(2)}
                    stat4Value={boxscoreData.possessions}
                  />
                  <RoleSummary
                    roleName="Playmaker"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.playmaker)}
                    previousScore={
                      props.firstRender ? 0 : previousScores.prevPlaymakerScore
                    }
                    stat1Name="AST"
                    stat2Name="TOV"
                    stat3Name="AST/TO"
                    stat4Name="PPP"
                    stat5Name="POSS"
                    stat1Value={boxscoreData.assists}
                    stat2Value={boxscoreData.turnovers}
                    stat3Value={boxscoreData.assiststurnover}
                    stat4Value={parseFloat(boxscoreData.ppp).toFixed(2)}
                    stat5Value={boxscoreData.possessions}
                  />
                  <RoleSummary
                    roleName="Rebounder"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.rebounder)}
                    previousScore={
                      props.firstRender ? 0 : previousScores.prevRebounderScore
                    }
                    stat1Name="REB"
                    stat2Name="OREB"
                    stat3Name="DREB"
                    stat4Name="POSS"
                    stat1Value={boxscoreData.totalreb}
                    stat2Value={boxscoreData.offreb}
                    stat3Value={boxscoreData.defreb}
                    stat4Value={boxscoreData.possessions}
                  />
                  <RoleSummary
                    roleName="Defender"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.defender)}
                    previousScore={
                      props.firstRender ? 0 : previousScores.prevDefenderScore
                    }
                    stat1Name="DREB"
                    stat2Name="BLK"
                    stat3Name="PF"
                    stat4Name="STL"
                    stat5Name="POSS"
                    stat1Value={boxscoreData.defreb}
                    stat2Value={boxscoreData.blocks}
                    stat3Value={boxscoreData.fouls}
                    stat4Value={boxscoreData.steals}
                    stat5Value={boxscoreData.possessions}
                  />
                  <RoleSummary
                    roleName="Rim Protector"
                    isMobile={props.isMobile}
                    roleScore={Math.round(boxscoreData.rimprotector)}
                    previousScore={
                      props.firstRender
                        ? 0
                        : previousScores.prevRimprotectorScore
                    }
                    stat1Name=""
                    stat2Name=""
                    stat3Name="BLK"
                    stat4Name="POSS"
                    stat1Value=""
                    stat2Value=""
                    stat3Value={boxscoreData.blocks}
                    stat4Value={boxscoreData.possessions}
                  />
                </Grid>
                {!props.isMobile && (
                  <Grid
                    item
                    xs={12}
                    md={5.5}
                    container
                    justifyContent="flex-end"
                    alignContent="flex-start"
                  >
                    <KeyStats
                      isMobile={props.isMobile}
                      season={props.season}
                      ppg={(
                        boxscoreData.points / boxscoreData.gamesplayed
                      ).toFixed(1)}
                      rpg={(
                        boxscoreData.totalreb / boxscoreData.gamesplayed
                      ).toFixed(1)}
                      apg={(
                        boxscoreData.assists / boxscoreData.gamesplayed
                      ).toFixed(1)}
                      fgpct={fgPct}
                    />
                    <ShotChartControls
                      isMobile={props.isMobile}
                      league={props.league}
                      playerId={props.playerId}
                      season={props.season}
                    />
                  </Grid>
                )}
                {props.isMobile && (
                  <Grid
                    item
                    xs={12}
                    md={5.5}
                    container
                    justifyContent="flex-end"
                    alignContent="flex-start"
                    sx={{ marginTop: props.isMobile ? "-35px" : "" }}
                  >
                    <ShotChartControls
                      isMobile={props.isMobile}
                      league={props.league}
                      playerId={props.playerId}
                      season={props.season}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Container>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
}
